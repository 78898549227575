"use client";

import { SIGNIN_ROUTE } from "@/lib/constants/routes";
import { useCurrentUser } from "@/lib/hooks/use-current-user";
import { cn } from "@/lib/utils";
import {
  ArrowLineRight,
  ArrowRightLg,
  CircleHalf,
  GearSix,
  Layout,
  List,
  Monitor,
  Moon,
  Plus,
  Sun,
  UserCircle,
  X,
} from "@jengaicons/react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { buttonVariants } from "@primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@primitives/dropdown";
import { Column, Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@primitives/sheet";
import { Text } from "@primitives/text";
import { signOut } from "next-auth/react";
import { useTheme } from "next-themes";
import Image from "next/image";
import React from "react";

import { company, HeaderLink, products, resources } from "./header";

export default function MobileHeader() {
  const { user, loading } = useCurrentUser();
  const { theme, setTheme } = useTheme();
  return (
    <Sheet>
      <SheetTrigger className="ml-auto grid h-9 w-9 place-items-center md:hidden">
        <List size={16} />
      </SheetTrigger>
      <SheetContent
        overlayStyles="md:hidden"
        showClose={false}
        className="bg-subdued right-0 top-0 flex h-screen w-full flex-col justify-between overflow-y-auto p-0 md:hidden"
        side="left"
      >
        <div>
          <div className="flex min-h-[60px] w-full items-center justify-between border-b px-6">
            <Link href="/" className=" ">
              <Image
                alt="site-logo"
                width={113}
                height={25}
                className="h-[25px] w-[113px] dark:invert"
                priority
                src="/outpost-logo.svg"
              />
            </Link>
            <SheetClose>
              <X size={20} />
            </SheetClose>
          </div>
          {user && (
            <Column className="border-b px-8 py-4">
              <Link
                href={`/${user.name}`}
                className="flex flex-row items-center gap-2 py-3"
              >
                <UserCircle size={16} />
                <Text>Profile</Text>
              </Link>
              <Link href={`/${user.name}`} className="items-center gap-2 py-3">
                <Layout size={16} />
                <Text variant={"bodyLg"}>Dashboard</Text>
              </Link>
              <Link
                href={`/settings/general`}
                className="items-center gap-2 py-3"
              >
                <GearSix size={16} />
                <Text variant={"bodyLg"}>Settings</Text>
              </Link>
              <Link
                href={`/new/repository`}
                className="items-center gap-2 py-3"
              >
                <Plus size={16} />
                <Text variant={"bodyLg"}>Create new</Text>
              </Link>
            </Column>
          )}
          <Column className="pt-4">
            <Column className="flex-1 gap-2 px-4">
              <Accordion type="single" collapsible>
                <AccordionItem value="1" className="m-0 border-none p-0">
                  <AccordionTrigger iconPosition="right" className="px-4">
                    <Text variant={"bodyLg"} weight={"medium"}>
                      Products
                    </Text>
                  </AccordionTrigger>
                  <AccordionContent className="ml-1 mt-1 space-y-2 p-0">
                    {products.map((component) => (
                      <ListItem
                        key={component.title}
                        title={component.title}
                        href={component.href}
                        icon={component.icon}
                        description={component.description}
                      />
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <Accordion type="single" collapsible>
                <AccordionItem value="1" className="m-0 border-none p-0">
                  <AccordionTrigger iconPosition="right" className="px-4">
                    <Text variant={"bodyLg"} weight={"medium"}>
                      Resources
                    </Text>
                  </AccordionTrigger>
                  <AccordionContent className="ml-1 mt-1 space-y-2 p-0">
                    {resources.map((component) => (
                      <ListItem
                        key={component.title}
                        title={component.title}
                        href={component.href}
                        icon={component.icon}
                        description={component.description}
                      />
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <Accordion type="single" collapsible>
                <AccordionItem value="1" className="m-0 border-none p-0">
                  <AccordionTrigger iconPosition="right" className="px-4">
                    <Text variant={"bodyLg"} weight={"medium"}>
                      Company
                    </Text>
                  </AccordionTrigger>
                  <AccordionContent className="ml-1 mt-1 space-y-2 p-0">
                    {company.map((component) => (
                      <ListItem
                        key={component.title}
                        title={component.title}
                        href={component.href}
                        icon={component.icon}
                        description={component.description}
                      />
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>

              <HeaderLink href="/pricing">
                <Text variant={"bodyLg"} weight={"medium"} className="px-[1px]">
                  Pricing
                </Text>
              </HeaderLink>
            </Column>
          </Column>
        </div>

        {user ? (
          <Column className="border-t px-8 py-4">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Row className="flex flex-row items-center gap-2 py-3">
                  <CircleHalf size={16} />
                  <Text>Theme</Text>
                </Row>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="z-[1000001]">
                <DropdownMenuItem
                  onClick={() => {
                    setTheme("light");
                  }}
                  className={`flex items-center gap-2 ${
                    theme === "light" ? "bg-active" : ""
                  }`}
                >
                  <Sun size={16} />

                  <span>Light</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => {
                    setTheme("dark");
                  }}
                  className={`flex items-center gap-2 ${
                    theme === "dark" ? "bg-active" : ""
                  }`}
                >
                  <Moon size={16} />

                  <span>Dark</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setTheme("system");
                  }}
                  className={`flex items-center gap-2 ${
                    theme === "system" ? "bg-active" : ""
                  }`}
                >
                  <Monitor size={16} />

                  <span>System</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Row
              onClick={() => {
                signOut({
                  redirect: true,
                  callbackUrl: SIGNIN_ROUTE,
                });
              }}
              className="items-center gap-2 py-3"
            >
              <ArrowLineRight size={16} />
              <Text variant={"bodyLg"}>Logout</Text>
            </Row>
          </Column>
        ) : (
          <Column className="gap-4 px-8 py-4 pb-8">
            <Link
              color="default"
              href="/signin"
              className={buttonVariants({
                variant: "outline",
                fullWidth: true,
                className: "py-4",
              })}
            >
              Log in
            </Link>
            <Link
              target="_blank"
              href="/signup"
              className={buttonVariants({
                fullWidth: true,
                className: "py-4",
              })}
            >
              <span>Sign up</span>
              <ArrowRightLg size="16" />
            </Link>
          </Column>
        )}
      </SheetContent>
    </Sheet>
  );
}

export function ListItem(props: {
  title: string;
  href: string;
  description: string;
  icon: JSX.Element;
}) {
  return (
    <Link
      href={props.href}
      className="group flex h-[72px] w-full flex-row items-center justify-start rounded-lg px-2 py-6"
    >
      <div className="group-hover:text-primary bg-active flex size-[56px] min-w-[56px] items-center justify-center rounded-lg">
        {props.icon}
      </div>
      <div
        className={cn(
          "block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors",
        )}
      >
        <div className="text-sm font-medium leading-none">{props.title}</div>
        <p className="text-muted-foreground text-soft line-clamp-2 text-sm leading-snug">
          {props.description}
        </p>
      </div>
    </Link>
  );
}
